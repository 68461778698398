import { useState, useEffect } from 'react';
import './App.scss';
import NumberModule from './components/NumberModule';

function App() {
  const [active, setActive] = useState(null);
  const [multiplesOf, setMultiplesOf] = useState([]);
  const numbersArr = [...Array(144).keys()];
  delete numbersArr[0];

  useEffect(() => {
    document.title = "Multiples";
  }, [])


  function handleClick(i) {
    setActive(i);

    const getMultiplesOf = (numbersArr, i) => numbersArr.filter(n => !(n % i));
    setMultiplesOf(getMultiplesOf(numbersArr, i));
  }


  return (
    <article className="app-wrapper">
      <header>
        <h1>Multiples</h1>
        <p>Click a number to highlight its multiples</p>
      </header>

      {numbersArr.map((number, i) =>
        <NumberModule
          key={i}
          displayNumber={i}
          onClick={() => handleClick(i)}
          isActive={active === i ? true : false}
          isMultiple={multiplesOf.indexOf(i) !== -1 ? true : false}
        />)}
    </article>
  );
}

export default App;
