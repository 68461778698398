function NumberModule(props) {
  return (
    <div
      className={`number-module ` + (props.isActive ? "active" : "" + (props.isMultiple ? "multiple" : ""))}
      onClick={props.onClick} >
      {props.displayNumber}
    </div>
  );
}

export default NumberModule;